<template>
  <sheet-drawer v-model="drawer" @close="closeView">
    <v-form class="fill-height" @submit.prevent v-if="obModel && drawer">
      <gw-form-observer
        @cancel="closeView"
        :loading="isLoading"
        hide-save-action
        cancel-label="close"
      >
        <price-list-table :price-list="obModel" />
      </gw-form-observer>
    </v-form>
  </sheet-drawer>
</template>

<script lang="ts">
import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import { PriceList } from "@planetadeleste/vue-mc-gw";
import PricelistsMixin from "@/modules/pricelists/mixins/PricelistsMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import PriceListTable from "@/modules/pricelists/components/PriceListTable.vue";

@Component({
  components: { PriceListTable, SheetDrawer },
})
export default class PriceListTableDrawer extends Mixins(PricelistsMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  @Prop(Object) readonly priceList!: PriceList;
}
</script>
